import {usePage} from "@inertiajs/vue3";

export default {
    methods: {
        /**
         * Translate the given key.
         */
        __(key, replace = {}) {
            var translation = getValue(usePage().props.messages, key)
                ? getValue(usePage().props.messages, key)
                : key

            Object.keys(replace).forEach(function (key) {
                translation = translation.replace(':' + key, replace[key])
            });

            return translation
        },

        /**
         * Translate the given key with basic pluralization.
         */
        __n(key, number, replace = {}) {
            var options = key.split('|');

            key = options[1];
            if(number == 1) {
                key = options[0];
            }

            return tt(key, replace);
        },
    },
}

function getValue(object, path) {

    // Break input path into parts separated by '.' via split
    const parts = path.split(".");

    // Iterate the parts of the path, and reduce that parts array to a single
    // value that we incrementally search for by current key, from a prior
    // nested "value"
    return parts.reduce((value, key) => value ? value[key] : value, object)
}
